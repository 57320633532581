import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { abbreviateNumber } from "js-abbreviation-number";
import { Skeleton, styled, List, Paper, Pagination } from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";

import { API_BASE_URL } from "../../config/api";

import { ErrorResponseData } from "../../types/api";
import { RepoType } from "../../types/repo";
import { TotalCount } from "../../types";

const HomeLayout = styled("div")(() => ({
  display: `flex`,
  flexDirection: `column`,
  flex: `1`,
}));

const SearchContentFail = {
  icon: {
    fontSize: `36px`,
    height: `39px`,
  },
  p: {
    marginTop: 0,
    marginBottom: 0,
    color: `#000`,
  },
};

const WrapperStart = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  textAlign: `center`,
  color: `rgba(0,0,0,0.5)`,
}));

const ContentResult = {
  textXS: {
    fontSize: `14px`,
    margin: `0`,
  },
  skeleton: {
    width: `calc(50% - .375rem)`,
  },
  infoWrap: {
    display: `flex`,
    flexDirection: `column`,
    gap: `.75rem`,
  } as React.CSSProperties,
  userGroup: {
    display: `flex`,
    flexWrap: `wrap`,
    gap: `.75rem`,
    marginBottom: `1.5rem`,
    width: `100%`,
  } as React.CSSProperties,
  repoWrap: {
    width: `calc(50% - .375rem)`,
    padding: `.5rem`,
    display: `flex`,
    flexDirection: `column`,
    gap: `.75rem`,
  } as React.CSSProperties,
  card: {
    avatarWrap: {
      width: `64px`,
      height: `64px`,
      avatar: {
        // maxWidth: `100%`,
        // maxHeight: `100%`,
        width: `64px`,
        height: `64px`,
      } as React.CSSProperties,
    },
    nameWrap: {
      display: `flex`,
      justifyContent: `space-between`,
      gap: `.5rem`,
      width: `100%`,
      favorite: {
        color: `#F44336`,
        cursor: `pointer`,
      },
    },
    repoName: {
      wordBreak: `break-word`,
    } as React.CSSProperties,
  },
  p: {
    marginTop: `0px`,
    marginBottom: `0px`,
  },
  pagination: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100%`,
  },
};

const WrapperResult = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexWrap: `wrap`,
  gap: `.75rem`,
  marginTop: `1rem`,
  width: `100%`,
}));

const SkeletonGroup = styled("div")(() => ({
  display: `flex`,
  gap: `.75rem`,
}));

const CardSkeleton: React.FC = (): JSX.Element => {
  return (
    <SkeletonGroup
      style={ContentResult.skeleton}
      className="skeleton-responsive"
    >
      <div>
        <Skeleton variant="rectangular" width={64} height={64} />
      </div>
      <div style={ContentResult.infoWrap}>
        <div>
          <Skeleton variant="text" width={50} height={24} />
        </div>
        <div>
          <Skeleton variant="text" width={80} height={16} />
          <Skeleton variant="text" width={80} height={16} />
        </div>
      </div>
    </SkeletonGroup>
  );
};

const Repo: React.FC<TotalCount> = (props: TotalCount): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      fetchRepo(value);
    }
  };

  let { username } = useParams();

  const [repoData, setRepoData] = useState([]);
  const fetchRepo = async (page?: number) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/users/${username}/repos?per_page=${pageSize}&page=${page}`
      );
      setRepoData(data);
      // setCount(data.total_count);
      setLoading(false);
    } catch (error: unknown) {
      const err: AxiosError<ErrorResponseData> =
        error as AxiosError<ErrorResponseData>;

      if (err.response) {
        console.log(
          "GET User Error:",
          err.response.data.message
            ? err.response.data.message
            : "Failed to search User"
        );
        alert(
          err.response.data.message
            ? err.response.data.message +
                `. Current result from Page ${currentPage}.`
            : "Failed to search User"
        );
        setCurrentPage(currentPage);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchRepo(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeLayout className="userdetail-box">
      {loading ? (
        <List>
          <WrapperResult>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </WrapperResult>
        </List>
      ) : repoData.length > 0 ? (
        <List sx={{ pb: 0 }}>
          <WrapperResult>
            <div style={ContentResult.userGroup}>
              {repoData.map((item: RepoType) => {
                return (
                  <Paper
                    style={ContentResult.repoWrap}
                    key={item.id}
                    className="card-grid"
                  >
                    <div style={ContentResult.card.nameWrap}>
                      <div style={ContentResult.card.repoName}>
                        <strong>{item.name}</strong>
                      </div>
                    </div>
                    <div>
                      <p style={ContentResult.textXS}>
                        {`${abbreviateNumber(item.stargazers_count)} ${
                          item.stargazers_count > 1 ? "stars" : "star"
                        }`}
                      </p>
                      <p style={ContentResult.textXS}>
                        {`${abbreviateNumber(item.forks_count)} ${
                          item.forks_count > 1 ? "forks" : "fork"
                        }`}
                      </p>
                    </div>
                  </Paper>
                );
              })}
            </div>
            <div style={ContentResult.pagination}>
              <Pagination
                count={Math.ceil((props?.count || 1) / pageSize)}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handleChange}
              />
            </div>
          </WrapperResult>
        </List>
      ) : (
        <WrapperStart>
          <div style={SearchContentFail.icon}>
            <ArticleIcon fontSize={"inherit"} />
          </div>
          <p style={SearchContentFail.p}>No public repositories.</p>
        </WrapperStart>
      )}
    </HomeLayout>
  );
};

export default Repo;
