import { Switch } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../state";
import HomeIcon from "@mui/icons-material/Home";

const Header: React.FC = () => {
  const Flex = styled("div")(() => ({
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    padding: `2rem 1rem 1rem 1rem`,
  }));

  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { changeTheme } = bindActionCreators(actionCreators, dispatch);

  const theme = useSelector((state: State) => state.theme);

  return (
    <Flex>
      <div>
        {pathname === "/" ? (
          "Search"
        ) : pathname === "/liked" ? (
          "Favorite"
        ) : (
          <HomeIcon
            onClick={() => navigate("/")}
            style={{ cursor: `pointer` }}
          />
        )}
      </div>
      <Switch
        checked={theme === "dark"}
        onChange={(e) => changeTheme(theme === "dark" ? "light" : "dark")}
      />
    </Flex>
  );
};

export default Header;
