import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";

const Footer: React.FC = () => {
  const [value, setValue] = useState(0);
  let navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      setValue(0);
    } else if (pathname === "/liked") {
      setValue(1);
    } else {
      setValue(2); // UserDetail
    }
  }, [pathname]);

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      style={{ justifyContent: `space-around` }}
    >
      <BottomNavigationAction
        label="Search"
        icon={<SearchIcon />}
        onClick={() => navigate("/")}
      />
      <BottomNavigationAction
        label="Favorite"
        icon={<FavoriteIcon />}
        onClick={() => navigate("/liked")}
      />
    </BottomNavigation>
  );
};

export default Footer;
