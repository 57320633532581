import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { Link, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { abbreviateNumber } from "js-abbreviation-number";

import { Skeleton, styled, List, Paper, Pagination } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupIcon from "@mui/icons-material/Group";

import { API_BASE_URL } from "../../config/api";

import { ErrorResponseData } from "../../types/api";

import { User, UserResponseType, UserSingleType } from "../../state/actions";
import { actionCreators, State } from "../../state";
import { TotalCount } from "../../types";
import Image from "../../components/Image";

const HomeLayout = styled("div")(() => ({
  display: `flex`,
  flexDirection: `column`,
  flex: `1`,
}));

const SearchContentFail = {
  icon: {
    fontSize: `36px`,
    height: `39px`,
  },
  p: {
    marginTop: 0,
    marginBottom: 0,
    color: `#000`,
  },
};

const WrapperStart = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  textAlign: `center`,
  color: `rgba(0,0,0,0.5)`,
}));

const ContentGeneral = {
  fullWidth: {
    width: `100%`,
  },
  link: {
    textDecoration: `none`,
    color: `unset`,
    wordBreak: `break-all`,
  } as React.CSSProperties,
};

const ContentResult = {
  textXS: {
    fontSize: `12px`,
  },
  skeleton: {
    width: `calc(50% - .375rem)`,
  },
  infoWrap: {
    display: `flex`,
    flexDirection: `column`,
    gap: `.75rem`,
  } as React.CSSProperties,
  userGroup: {
    display: `flex`,
    flexWrap: `wrap`,
    gap: `.75rem`,
    marginBottom: `1.5rem`,
    width: `100%`,
  } as React.CSSProperties,
  cardWrap: {
    width: `calc(50% - .375rem)`,
    display: `flex`,
    padding: `.5rem`,
    gap: `.75rem`,
    textDecoration: `none`,
    card: {
      width: `100%`,
      padding: `.5rem`,
      display: `flex`,
      gap: `.75rem`,
      avatarWrap: {
        width: `64px`,
        height: `64px`,
        avatar: {
          // maxWidth: `100%`,
          // maxHeight: `100%`,
          width: `64px`,
          height: `64px`,
        } as React.CSSProperties,
      },
      nameWrap: {
        display: `flex`,
        justifyContent: `space-between`,
        gap: `.5rem`,
        width: `100%`,
        favorite: {
          color: `#F44336`,
          cursor: `pointer`,
        },
      },
    },
  },
  pagination: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100%`,
  },
};

const WrapperResult = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexWrap: `wrap`,
  gap: `.75rem`,
  marginTop: `1rem`,
  width: `100%`,
}));

const SkeletonGroup = styled("div")(() => ({
  display: `flex`,
  gap: `.75rem`,
}));

const CardSkeleton: React.FC = (): JSX.Element => {
  return (
    <SkeletonGroup
      style={ContentResult.skeleton}
      className="skeleton-responsive"
    >
      <div>
        <Skeleton variant="rectangular" width={64} height={64} />
      </div>
      <div style={ContentResult.infoWrap}>
        <div>
          <Skeleton variant="text" width={50} height={24} />
        </div>
        <div>
          <Skeleton variant="text" width={80} height={16} />
          <Skeleton variant="text" width={80} height={16} />
        </div>
      </div>
    </SkeletonGroup>
  );
};

const Follower: React.FC<TotalCount> = (props: TotalCount): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const favorites = useSelector((state: State) => state.favorite);

  const dispatch = useDispatch();

  const { favoriteUser } = bindActionCreators(actionCreators, dispatch);

  const onFavUser = (user: User) => {
    let userObj = {
      id: user.id,
      login: user.login,
      avatar_url: user.avatar_url,
      followers_url: user.followers_url,
      following_url: user.following_url,
      follower: user.follower,
      following: user.following,
      repos_url: user.repos_url,
      html_url: user.html_url,
    };
    favoriteUser(userObj);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      fetchFollower(value);
    }
  };

  let { username } = useParams();

  const [followerData, setFollowerData] = useState([]);
  const fetchFollower = (page?: number) => {
    setLoading(true);
    let tempData: any = [];

    axios
      .get(
        `${API_BASE_URL}/users/${username}/followers?per_page=${pageSize}&page=${page}`
      )
      .then((users) => {
        const { data } = users;
        tempData = data;

        let promises: Array<string> = [];

        // Fetch Follower and Following
        data.map((url: UserSingleType) => promises.push(url.url));
        Promise.all(promises.map((promise: string) => axios.get(promise))).then(
          (values: Array<UserResponseType>) => {
            values.forEach((response: UserResponseType) => {
              const indexx = tempData.findIndex(
                (d: User) => d.login === response.data.login // values is array
              );
              tempData[indexx].follower = response.data.followers;
              tempData[indexx].following = response.data.following;
            });
            setFollowerData(tempData);
            setLoading(false);
          }
        );
      })
      .catch((error: unknown) => {
        const err: AxiosError<ErrorResponseData> =
          error as AxiosError<ErrorResponseData>;

        if (err.response) {
          console.log(
            "GET User Error:",
            err.response.data.message
              ? err.response.data.message
              : "Failed to search User"
          );
          alert(
            err.response.data.message
              ? err.response.data.message +
                  `. Current result from Page ${currentPage}.`
              : "Failed to search User"
          );
          setCurrentPage(currentPage);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    void fetchFollower(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeLayout className="userdetail-box">
      {loading ? (
        <List>
          <WrapperResult>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </WrapperResult>
        </List>
      ) : followerData.length > 0 ? (
        <List sx={{ pb: 0 }}>
          <WrapperResult>
            <div style={ContentResult.userGroup}>
              {followerData.map((item: User) => {
                return (
                  <Paper
                    style={ContentResult.cardWrap}
                    key={item.id}
                    className="card-grid"
                  >
                    <div style={ContentResult.cardWrap.card.avatarWrap}>
                      <Link to={`/users/${item.login}`}>
                        <Image
                          src={item.avatar_url}
                          alt={item.login}
                          style={ContentResult.cardWrap.card.avatarWrap.avatar}
                        />
                      </Link>
                    </div>
                    <div style={ContentGeneral.fullWidth}>
                      <div style={ContentResult.cardWrap.card.nameWrap}>
                        <div>
                          <Link
                            to={`/users/${item.login}`}
                            style={ContentGeneral.link}
                          >
                            {item.login}
                          </Link>
                        </div>
                        <div
                          style={ContentResult.cardWrap.card.nameWrap.favorite}
                          onClick={() => onFavUser(item)}
                        >
                          {favorites.length > 0 &&
                          favorites.some(
                            (el: User) =>
                              el.id.toString() === item.id.toString()
                          ) > 0 ? (
                            <FavoriteIcon />
                          ) : (
                            <FavoriteBorderIcon />
                          )}
                        </div>
                      </div>
                      <div style={ContentResult.textXS}>
                        {abbreviateNumber(item?.follower)}{" "}
                        {item.follower > 1 ? "followers" : "follower"}
                      </div>
                      <div style={ContentResult.textXS}>
                        {abbreviateNumber(item?.following)}{" "}
                        {item.following > 1 ? "followings" : "following"}
                      </div>
                    </div>
                  </Paper>
                );
              })}
            </div>
            <div style={ContentResult.pagination}>
              <Pagination
                count={Math.ceil((props?.count || 1) / pageSize)}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handleChange}
              />
            </div>
          </WrapperResult>
        </List>
      ) : (
        <WrapperStart>
          <div style={SearchContentFail.icon}>
            <GroupIcon fontSize={"inherit"} />
          </div>
          <p style={SearchContentFail.p}>No followers.</p>
        </WrapperStart>
      )}
    </HomeLayout>
  );
};

export default Follower;
