import React from "react";
import { ImageType } from "../types";

const Image: React.FC<ImageType> = (props: ImageType): JSX.Element => {
  return (
    <img
      src={props.src}
      alt={props.alt}
      onError={({ currentTarget }) => {
        currentTarget.src = "https://via.placeholder.com/150";
      }}
      style={props.style}
    />
  );
};

export default Image;
