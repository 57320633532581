import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { styled, List, Paper, Pagination } from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { getFavorite } from "../utils/storage";

import { User } from "../state/actions";
import { actionCreators, State } from "../state";
import { abbreviateNumber } from "js-abbreviation-number";
import Image from "../components/Image";

const HomeLayout = styled("div")(() => ({
  display: `flex`,
  flexDirection: `column`,
  flex: `1`,
}));

const SearchContentFail = {
  icon: {
    fontSize: `36px`,
    height: `39px`,
  },
  p: {
    marginTop: 0,
    marginBottom: 0,
    color: `#000`,
  },
};

const WrapperStart = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  textAlign: `center`,
  color: `rgba(0,0,0,0.5)`,
}));

const ContentGeneral = {
  link: {
    textDecoration: `none`,
    color: `unset`,
    wordBreak: `break-all`,
  } as React.CSSProperties,
};

const ContentResult = {
  textXS: {
    fontSize: `12px`,
  },
  skeleton: {
    width: `calc(50% - .375rem)`,
  },
  infoWrap: {
    display: `flex`,
    flexDirection: `column`,
    gap: `.75rem`,
  } as React.CSSProperties,
  userGroup: {
    display: `flex`,
    flexWrap: `wrap`,
    gap: `.75rem`,
    marginBottom: `1.5rem`,
    width: `100%`,
  } as React.CSSProperties,
  cardWrap: {
    width: `calc(50% - .375rem)`,
    display: `flex`,
    padding: `.5rem`,
    gap: `.75rem`,
    textDecoration: `none`,
    card: {
      width: `100%`,
      padding: `.5rem`,
      display: `flex`,
      gap: `.75rem`,
      avatarWrap: {
        width: `64px`,
        height: `64px`,
        avatar: {
          // maxWidth: `100%`,
          // maxHeight: `100%`,
          width: `64px`,
          height: `64px`,
        } as React.CSSProperties,
      },
      nameWrap: {
        display: `flex`,
        justifyContent: `space-between`,
        gap: `.5rem`,
        width: `100%`,
        favorite: {
          color: `#F44336`,
          cursor: `pointer`,
        },
      },
    },
  },
  pagination: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100%`,
  },
};

const WrapperResult = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexWrap: `wrap`,
  gap: `.75rem`,
  marginTop: `1rem`,
  width: `100%`,
}));

const Liked: React.FC = (): JSX.Element => {
  const [pageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const favorites = useSelector((state: State) => state.favorite);

  const dispatch = useDispatch();

  const { favoriteUser } = bindActionCreators(actionCreators, dispatch);

  const onFavUser = (user: User) => {
    let userObj = {
      id: user.id,
      login: user.login,
      avatar_url: user.avatar_url,
      followers_url: user.followers_url,
      following_url: user.following_url,
      follower: user.follower,
      following: user.following,
      repos_url: user.repos_url,
      html_url: user.html_url,
    };
    favoriteUser(userObj);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (currentPage !== value) {
      setCurrentPage(value);
    }
  };

  useEffect(() => {
    setData(getFavorite());
  }, []);

  return (
    <HomeLayout>
      {data && data.length > 0 ? (
        <List sx={{ pb: 9 }}>
          <WrapperResult>
            <div style={ContentResult.userGroup}>
              {data
                .slice((currentPage - 1) * pageSize, pageSize * currentPage)
                .map((item: User) => {
                  return (
                    // <Link
                    //   to={`/users/${item.login}`}
                    //   style={ContentResult.cardWrap}
                    // >
                    <Paper
                      style={ContentResult.cardWrap}
                      key={item.id}
                      className="card-grid"
                    >
                      <div style={ContentResult.cardWrap.card.avatarWrap}>
                        <Link to={`/users/${item.login}`}>
                          <Image
                            src={item.avatar_url}
                            alt={item.login}
                            style={
                              ContentResult.cardWrap.card.avatarWrap.avatar
                            }
                          />
                        </Link>
                      </div>
                      <div>
                        <div style={ContentResult.cardWrap.card.nameWrap}>
                          <div>
                            <Link
                              to={`/users/${item.login}`}
                              style={ContentGeneral.link}
                            >
                              {item.login}
                            </Link>
                          </div>
                          <div
                            style={
                              ContentResult.cardWrap.card.nameWrap.favorite
                            }
                            onClick={() => onFavUser(item)}
                          >
                            {favorites.length > 0 &&
                            favorites.some(
                              (el: User) =>
                                el.id.toString() === item.id.toString()
                            ) > 0 ? (
                              <FavoriteIcon />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                          </div>
                        </div>

                        <div style={ContentResult.textXS}>
                          {abbreviateNumber(item.follower)}{" "}
                          {item.follower > 1 ? "followers" : "follower"}
                        </div>
                        <div style={ContentResult.textXS}>
                          {abbreviateNumber(item.following)}{" "}
                          {item.following > 1 ? "followings" : "following"}
                        </div>
                      </div>
                    </Paper>
                    // </Link>
                  );
                })}
            </div>
            <div style={ContentResult.pagination}>
              <Pagination
                count={Math.ceil(data.length / pageSize)}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handleChange}
              />
            </div>
          </WrapperResult>
        </List>
      ) : (
        <WrapperStart>
          <div style={SearchContentFail.icon}>
            <GroupIcon fontSize={"inherit"} />
          </div>
          <p style={SearchContentFail.p}>
            Once you like people, you'll see them here.
          </p>
        </WrapperStart>
      )}
    </HomeLayout>
  );
};

export default Liked;
