import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { FavoriteAction, ThemeAction, User } from "../actions";

export const changeTheme = (theme: string) => {
  localStorage.setItem("theme", theme);
  return (dispatch: Dispatch<ThemeAction>) => {
    dispatch({
      type: ActionType.CHANGE_THEME,
      payload: theme,
    });
  };
};

export const favoriteUser = (user: User) => {
  return (dispatch: Dispatch<FavoriteAction>) => {
    dispatch({
      type: ActionType.FAVORITE_USER,
      payload: user,
    });
  };
};
