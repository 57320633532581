import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Skeleton,
  styled,
  TextField,
  List,
  Paper,
  Pagination,
} from "@mui/material";

import GitHubIcon from "@mui/icons-material/GitHub";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
// import { Octokit } from "@octokit/core";
import { API_BASE_URL } from "../config/api";

import { ErrorResponseData } from "../types/api";

import { actionCreators, State } from "../state";
import { User, UserResponseType, UserSingleType } from "../state/actions";
import { Link } from "react-router-dom";
import { abbreviateNumber } from "js-abbreviation-number";
import Image from "../components/Image";
// import { GITHUB_TOKEN } from "../config";

// const octokit = new Octokit({
//   auth: `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`,
// });

// axios.defaults.headers.common["authorization"] = "token " + GITHUB_TOKEN; // Enable higher limit with personal token - replace GITHUB_TOKEN if limitation occurs.

const TextFieldStyle = {
  width: `100%`,
} as React.CSSProperties;

const HomeLayout = styled("div")(() => ({
  display: `flex`,
  flexDirection: `column`,
  flex: `1`,
}));

const SearchContentStart = {
  icon: {
    fontSize: `120px`,
    height: `132px`,
  },
  h2: {
    marginTop: 0,
    marginBottom: `.5rem`,
  },
  p: {
    marginTop: 0,
    marginBottom: 0,
  },
};

const SearchContentFail = {
  icon: {
    fontSize: `36px`,
    height: `39px`,
  },
  p: {
    marginTop: 0,
    marginBottom: 0,
    color: `#000`,
  },
};

const WrapperStart = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  textAlign: `center`,
  color: `rgba(0,0,0,0.5)`,
}));

const ContentGeneral = {
  fullWidth: {
    width: `100%`,
  },
  link: {
    textDecoration: `none`,
    color: `unset`,
    wordBreak: `break-all`,
  } as React.CSSProperties,
};

const ContentResult = {
  textXS: {
    fontSize: `12px`,
  },
  skeleton: {
    width: `calc(50% - .375rem)`,
  },
  infoWrap: {
    display: `flex`,
    flexDirection: `column`,
    gap: `.75rem`,
  } as React.CSSProperties,
  userGroup: {
    display: `flex`,
    flexWrap: `wrap`,
    gap: `.75rem`,
    marginBottom: `1.5rem`,
    width: `100%`,
  } as React.CSSProperties,
  card: {
    width: `calc(50% - .375rem)`,
    padding: `.5rem`,
    display: `flex`,
    gap: `.75rem`,
    avatarWrap: {
      width: `64px`,
      height: `64px`,
      avatar: {
        // maxWidth: `100%`,
        // maxHeight: `100%`,
        width: `64px`,
        height: `64px`,
      } as React.CSSProperties,
    },
    nameWrap: {
      display: `flex`,
      justifyContent: `space-between`,
      gap: `.5rem`,
      width: `100%`,
      favorite: {
        color: `#F44336`,
        cursor: `pointer`,
      },
    },
  },
  pagination: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100%`,
  },
};

const WrapperResult = styled("div")(() => ({
  display: `flex`,
  flex: `1`,
  flexWrap: `wrap`,
  gap: `.75rem`,
  marginTop: `1rem`,
  width: `100%`,
}));

const SkeletonGroup = styled("div")(() => ({
  display: `flex`,
  gap: `.75rem`,
}));

const CardSkeleton: React.FC = (): JSX.Element => {
  return (
    <SkeletonGroup
      style={ContentResult.skeleton}
      className="skeleton-responsive"
    >
      <div>
        <Skeleton variant="rectangular" width={64} height={64} />
      </div>
      <div style={ContentResult.infoWrap}>
        <div>
          <Skeleton variant="text" width={50} height={24} />
        </div>
        <div>
          <Skeleton variant="text" width={80} height={16} />
          <Skeleton variant="text" width={80} height={16} />
        </div>
      </div>
    </SkeletonGroup>
  );
};

const Home: React.FC = (): JSX.Element => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const { favoriteUser } = bindActionCreators(actionCreators, dispatch);

  const favorites = useSelector((state: State) => state.favorite);

  const onFavUser = (user: User) => {
    let userObj = {
      id: user.id,
      login: user.login,
      avatar_url: user.avatar_url,
      followers_url: user.followers_url,
      following_url: user.following_url,
      follower: user.follower,
      following: user.following,
      repos_url: user.repos_url,
      html_url: user.html_url,
    };
    favoriteUser(userObj);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      fetchUser(value);
    }
  };

  const fetchUser = (page?: number) => {
    setLoading(true);
    let tempData: any = [];

    axios
      .get(
        `${API_BASE_URL}/search/users?q=${search}+in:user&page=${
          page || currentPage
        }&per_page=${pageSize}`
      )
      .then((users) => {
        const { data } = users;
        tempData = data.items;
        setCount(data.total_count);

        let promises: Array<string> = [];

        // Fetch Follower and Following
        data.items.map((url: UserSingleType) => promises.push(url.url));
        Promise.all(promises.map((promise: string) => axios.get(promise))).then(
          (values: Array<UserResponseType>) => {
            console.log("values detail", values);
            values.forEach((response: UserResponseType) => {
              const indexx = tempData.findIndex(
                (d: User) => d.login === response.data.login // values is array
              );
              tempData[indexx].follower = response.data.followers;
              tempData[indexx].following = response.data.following;
            });
            console.log("after mod", tempData);
            setData(tempData);
            setLoading(false);
          }
        );
      })
      .catch((error: unknown) => {
        const err: AxiosError<ErrorResponseData> =
          error as AxiosError<ErrorResponseData>;

        if (err.response) {
          console.log(
            "GET User Error:",
            err.response.data.message
              ? err.response.data.message
              : "Failed to search User"
          );
          alert(
            err.response.data.message
              ? err.response.data.message +
                  `. Current result from Page ${currentPage}.`
              : "Failed to search User"
          );
          setCurrentPage(currentPage);
        }
        setLoading(false);
      });
  };

  const [timeDelay, setTimeDelay] = useState(800);
  const onSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      setTimeDelay(0);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        setCurrentPage(1);
        fetchUser(1);
        setTimeDelay(800);
      }
    }, timeDelay);

    return () => {
      clearTimeout(delayDebounceFn);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, timeDelay]);

  return (
    <HomeLayout>
      <TextField
        id="outlined-search"
        label=""
        placeholder="Enter GitHub username, i.e. gaearon"
        type="search"
        value={search}
        onKeyPress={(e) => onSearch(e)}
        onChange={(e) => setSearch(e.target.value)}
        style={TextFieldStyle}
      />
      {search.length < 1 ? (
        <WrapperStart>
          <div style={SearchContentStart.icon}>
            <GitHubIcon fontSize={"inherit"} />
          </div>
          <h2 style={SearchContentStart.h2}>GitHub</h2>
          <p style={SearchContentStart.p}>
            Enter GitHub username and search users matching the input like
            Google Search, click avatars to view more details, including
            repositories, followers and following.
          </p>
        </WrapperStart>
      ) : loading ? (
        <List>
          <WrapperResult>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </WrapperResult>
        </List>
      ) : data.length > 0 ? (
        <List sx={{ pb: 9 }}>
          <div>{count} GitHub users found</div>
          <WrapperResult>
            <div style={ContentResult.userGroup}>
              {data.map((item: User) => {
                return (
                  <Paper
                    style={ContentResult.card}
                    key={item.id}
                    className="card-grid"
                  >
                    <div style={ContentResult.card.avatarWrap}>
                      <Link to={`/users/${item.login}`}>
                        <Image
                          src={item.avatar_url}
                          alt={item.login}
                          style={ContentResult.card.avatarWrap.avatar}
                        />
                      </Link>
                    </div>
                    <div style={ContentGeneral.fullWidth}>
                      <div style={ContentResult.card.nameWrap}>
                        <div>
                          <Link
                            to={`/users/${item.login}`}
                            style={ContentGeneral.link}
                          >
                            {item.login}
                          </Link>
                        </div>
                        <div
                          style={ContentResult.card.nameWrap.favorite}
                          onClick={() => onFavUser(item)}
                        >
                          {favorites.length > 0 &&
                          favorites.some(
                            (el: User) =>
                              el.id.toString() === item.id.toString()
                          ) > 0 ? (
                            <FavoriteIcon />
                          ) : (
                            <FavoriteBorderIcon />
                          )}
                        </div>
                      </div>
                      <div style={ContentResult.textXS}>
                        {abbreviateNumber(item?.follower)}{" "}
                        {item.follower > 1 ? "followers" : "follower"}
                      </div>
                      <div style={ContentResult.textXS}>
                        {abbreviateNumber(item?.following)}{" "}
                        {item.following > 1 ? "followings" : "following"}
                      </div>
                    </div>
                  </Paper>
                );
              })}
            </div>
            <div style={ContentResult.pagination}>
              <Pagination
                count={Math.ceil(count / pageSize)}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handleChange}
              />
            </div>
          </WrapperResult>
        </List>
      ) : (
        <WrapperStart>
          <div style={SearchContentFail.icon}>
            <SearchIcon fontSize={"inherit"} />
          </div>
          <p style={SearchContentFail.p}>No search result found for</p>
          <p style={SearchContentFail.p}>
            <strong>{search}</strong>
          </p>
        </WrapperStart>
      )}
    </HomeLayout>
  );
};

export default Home;
