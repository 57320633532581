import { ActionType } from "../action-types";
import { ThemeAction } from "../actions";

const initialState =
  localStorage.getItem("theme") === "dark" ? "dark" : "light";

const reducer = (state: string = initialState, action: ThemeAction) => {
  if (action.type === ActionType.CHANGE_THEME) {
    return action.payload;
  } else {
    return state;
  }
};

export default reducer;
