import { User } from "../state/actions";

export const onFavoriteUser = (data: User) => {
  let cur = getFavorite();
  if (cur) {
    const index = cur.findIndex((d: User) => d.id === data.id);
    if (index < 0) {
      // append data to getFavorite
      cur.push(data);
      return localStorage.setItem("favorite", JSON.stringify(cur));
    } else {
      // remove data on getFavorite
      cur.splice(index, 1);
      return localStorage.setItem("favorite", JSON.stringify(cur));
    }
  } else {
    const newFav = [];
    newFav.push(data);
    return localStorage.setItem("favorite", JSON.stringify(newFav));
  }
};

export const getFavorite = () => {
  return JSON.parse(localStorage.getItem("favorite") || "[]");
};
