import { getFavorite, onFavoriteUser } from "../../utils/storage";
import { ActionType } from "../action-types";
import { FavoriteAction, User } from "../actions";

const initialState = getFavorite() || [];

const reducer = (state: User = initialState, action: FavoriteAction) => {
  if (action.type === ActionType.FAVORITE_USER) {
    onFavoriteUser(action.payload);
    return getFavorite();
  } else {
    return state;
  }
};

export default reducer;
