import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";

import { styled, Skeleton } from "@mui/material";

import ApartmentIcon from "@mui/icons-material/Apartment";

import { API_BASE_URL } from "../config/api";

import { ErrorResponseData } from "../types/api";

import { UserDet } from "../state/actions";

import BasicTabs from "../components/BasicTabs";

import Repo from "./userTab/Repo";
import Follower from "./userTab/Follower";
import Following from "./userTab/Following";
import Image from "../components/Image";

const ProfileLayout = styled("div")(() => ({
  display: `flex`,
  flexDirection: `column`,
  flex: `1`,
  paddingBottom: `3.5rem`,
}));

const ProfileInfo = {
  infoDiv: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`,
  } as React.CSSProperties,
  avatarDiv: {
    width: `160px`,
    height: `160px`,
    borderRadius: `50%`,
    overflow: `hidden`,
  } as React.CSSProperties,
  avatarImg: {
    maxWidth: `160px`,
    maxHeight: `160px`,
  },
  bio: {
    textAlign: `center`,
  } as React.CSSProperties,
  company: {
    display: `flex`,
    alignItems: `center`,
    margin: `1rem 0`,
  } as React.CSSProperties,
};

const ContentResult = {
  skeleton: {
    width: `100%`,
  },
  infoWrap: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    gap: `.75rem`,
  } as React.CSSProperties,
  tabWrap: {
    display: `flex`,
    justifyContent: `space-between`,
    gap: `1rem`,
    marginBottom: `1.5rem`,
  },
  details: {
    display: `flex`,
    maxWidth: `352px`,
    justifyContent: `center`,
    flexWrap: `wrap`,
    gap: `1rem`,
  } as React.CSSProperties,
  userGroup: {
    display: `flex`,
    flexWrap: `wrap`,
    gap: `.75rem`,
    marginBottom: `1.5rem`,
    width: `100%`,
  } as React.CSSProperties,
  card: {
    width: `calc(50% - .375rem)`,
    padding: `.5rem`,
    display: `flex`,
    gap: `.75rem`,
    avatarWrap: {
      width: `64px`,
      height: `64px`,
      avatar: {
        // maxWidth: `100%`,
        // maxHeight: `100%`,
        width: `64px`,
        height: `64px`,
      } as React.CSSProperties,
    },
    nameWrap: {
      display: `flex`,
      justifyContent: `space-between`,
      gap: `.5rem`,
      width: `100%`,
      favorite: {
        color: `#F44336`,
        cursor: `pointer`,
      },
    },
  },
  pagination: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100%`,
  },
};

const UserDetail: React.FC = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<UserDet>();

  const [loading, setLoading] = useState(false);
  let { username } = useParams();

  const fetchUserDetail = async (page?: number) => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${API_BASE_URL}/users/${username}`);
      setData(data);
      setLoading(false);
    } catch (error: unknown) {
      const err: AxiosError<ErrorResponseData> =
        error as AxiosError<ErrorResponseData>;

      if (err.response) {
        console.log(
          "GET User Error:",
          err.response.data.message
            ? err.response.data.message
            : "Failed to search User"
        );
        alert(
          err.response.data.message
            ? err.response.data.message +
                `. Current result from Page ${currentPage}.`
            : "Failed to search User"
        );
        setCurrentPage(currentPage);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const SkeletonGroup = styled("div")(() => ({
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
    gap: `.75rem`,
  }));

  const SkeletonDetail: React.FC = (): JSX.Element => {
    return (
      <SkeletonGroup style={ContentResult.skeleton}>
        <div>
          <Skeleton variant="circular" width={160} height={160} />
        </div>
        <div style={ContentResult.infoWrap}>
          <div>
            <Skeleton variant="text" width={180} height={24} />
          </div>
          <div>
            <Skeleton variant="text" width={120} height={16} />
          </div>
        </div>
        <div style={ContentResult.tabWrap}>
          <Skeleton variant="rectangular" width={90} height={40} />
          <Skeleton variant="rectangular" width={90} height={40} />
          <Skeleton variant="rectangular" width={90} height={40} />
        </div>
        <div style={ContentResult.details}>
          <Skeleton variant="rectangular" width={160} height={100} />
          <Skeleton variant="rectangular" width={160} height={100} />
          <Skeleton variant="rectangular" width={160} height={100} />
          <Skeleton variant="rectangular" width={160} height={100} />
        </div>
      </SkeletonGroup>
    );
  };

  return (
    <ProfileLayout>
      {loading ? (
        <SkeletonDetail />
      ) : (
        <div style={ProfileInfo.infoDiv}>
          <div style={ProfileInfo.avatarDiv}>
            <Image
              src={data?.avatar_url}
              alt={data?.login}
              style={ProfileInfo.avatarImg}
            />
          </div>
          <div>{data?.login}</div>
          <div style={ProfileInfo.bio}>{data?.bio}</div>
          <div style={ProfileInfo.company}>
            <ApartmentIcon />
            {data?.company ? data?.company : "-"}
          </div>
          <BasicTabs
            label={[
              { title: "REPOSITORIES", value: data?.public_repos || 0 },
              { title: "FOLLOWERS", value: data?.followers || 0 },
              { title: "FOLLOWING", value: data?.following || 0 },
            ]}
            repo={<Repo count={data?.public_repos} />}
            follower={<Follower count={data?.followers} />}
            following={<Following count={data?.following} />}
          />
        </div>
      )}
    </ProfileLayout>
  );
};

export default UserDetail;
