import { combineReducers } from "redux";
import themeReducer from "./themeReducer";
import favoriteReducer from "./favoriteReducer";

const reducers = combineReducers({
  theme: themeReducer,
  favorite: favoriteReducer,
});

export default reducers;

export type State = ReturnType<typeof reducers>;
