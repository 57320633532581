import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  Paper,
  PaletteMode,
  styled,
} from "@mui/material";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Liked from "./pages/Liked";
import UserDetail from "./pages/UserDetail";

import { State } from "./state";
import { useEffect } from "react";
import { setHeader } from "./utils/axios";
import { GITHUB_TOKEN } from "./config";

const PaperStyle = {
  minHeight: `100vh`,
  borderRadius: 0,
  position: `relative`,
  display: `flex`,
  flexDirection: `column`,
} as React.CSSProperties;

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light" && {
      background: {
        default: "#eee",
      },
    }),
  },
});

function App() {
  const LayoutContent = styled("div")(() => ({
    paddingLeft: `1rem`,
    paddingRight: `1rem`,
    display: `flex`,
    flexDirection: `column`,
    flex: `1`,
  }));

  const theme = useSelector((state: State) => state.theme);

  useEffect(() => {
    setHeader(GITHUB_TOKEN);
  }, []);

  return (
    <Router>
      <ThemeProvider
        theme={createTheme(
          getDesignTokens(theme === "dark" ? "dark" : "light")
        )}
      >
        <CssBaseline />
        <Container maxWidth="sm" sx={{ padding: 0 }}>
          <Paper elevation={1} style={PaperStyle}>
            <Header />
            <LayoutContent>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/liked" element={<Liked />} />
                <Route path="/users/:username" element={<UserDetail />} />
              </Routes>
            </LayoutContent>
            <Paper
              sx={{ position: "absolute", bottom: 0, width: `100%` }}
              elevation={2}
            >
              <Footer />
            </Paper>
          </Paper>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
